import React from "react";

import { Action, Category } from "constants/events";
import { useEventTracker } from "hooks/useEventTracker";
import isMobileDevice from "utils/isMobileDevice";

import Button from "components/botanic/Button";
import OpenInNewIcon from "components/Icons/open_in_new.svg";
import StoreWithBackgroundIcon from "components/Icons/store_with_background.svg";

const NonCompliantMenu: React.FC<{
  dispensaryId: number;
  dispensaryName: string;
  dispensaryWebsite?: string;
  hideCTA?: boolean;
  isLeaflyOrderingAllowed?: boolean;
  thirdPartyOrderingProhibited?: boolean;
}> = ({
  dispensaryId,
  dispensaryName,
  dispensaryWebsite,
  hideCTA,
  isLeaflyOrderingAllowed,
  thirdPartyOrderingProhibited,
}) => {
  const { publishEvent } = useEventTracker();
  const isMobile = isMobileDevice();

  const onClick = () => {
    thirdPartyOrderingProhibited
      ? publishEvent({
          action: Action.click,
          category: Category.outboundLink,
          dispensaryId,
          label: "markets egress",
        })
      : publishEvent({
          action: Action.click,
          category: Category.dispensaryMenu,
          dispensaryId,
          label: "visit store website",
        });
  };

  const name = dispensaryName.trim();

  return (
    <>
      {!!dispensaryWebsite && !hideCTA && thirdPartyOrderingProhibited && (
        <div className="container mb-xl">
          <div className="bg-leafly-white rounded px-lg py-xl text-center pb-xl">
            <div className="flex justify-center pb-sm">
              <StoreWithBackgroundIcon height="71" width="71" />
            </div>
            <p className="font-extrabold text-md pb-sm m-0">
              Shop on {name}'s website
            </p>
            <div className="text-sm text-center pb-lg">
              {isMobile ? (
                <p className="mb-0">
                  This menu is not available for ordering through Leafly due to
                  local laws, but don't sweat it! You can view {name}'s menu on
                  their website.
                </p>
              ) : (
                <div>
                  <p className="mb-0">
                    This menu is not available for ordering through Leafly due
                    to local laws, but don't sweat it!
                  </p>
                  <p className="m-0">
                    You can view {name}'s menu on their website.
                  </p>
                </div>
              )}
            </div>
            <div className="md:flex md:justify-center">
              <div className="md:w-1/3">
                <Button
                  href={`${
                    dispensaryWebsite.split("?")[0]
                  }?utm_source=leafly&utm_medium=referral&utm_campaign=markets-outbound`}
                  onClick={onClick}
                  onKeyDown={onClick}
                  size="full"
                  className="flex items-center justify-center non-compliant__button"
                  target="_blank"
                  rel="noopener noreferrer"
                  prefetch={false}
                  data-testid="non-compliant__button"
                >
                  order online
                  <OpenInNewIcon
                    height="24"
                    width="24"
                    className="text-white ml-sm"
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!!dispensaryWebsite &&
        !hideCTA &&
        !isLeaflyOrderingAllowed &&
        !thirdPartyOrderingProhibited && (
          <div className="container my-section">
            <div className="row justify-center text-center mt-lg lg:mt-xl">
              <div className="font-extrabold text-lg mb-xs">Don’t worry</div>
              <div className="mb-lg" data-testid="non-compliant__div">
                You can view this store’s menu on their website.
              </div>
              <div className="col md:col-1/2 lg:col-1/3 justify-center">
                <>
                  <Button
                    href={dispensaryWebsite}
                    prefetch={false}
                    onClick={onClick}
                    onKeyDown={onClick}
                    size="full"
                    className="flex items-center justify-center non-compliant__button"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="non-compliant__button"
                  >
                    <OpenInNewIcon
                      height="24"
                      width="24"
                      className="text-white mr-md"
                    />
                    visit store website
                  </Button>
                </>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default NonCompliantMenu;
