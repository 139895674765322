import debounce from "lodash/debounce";

const SCROLL_KEY = "shop-scroll";

export const scrollToSetPosition = () => {
  if (typeof sessionStorage !== "undefined") {
    const scrollPosition = sessionStorage.getItem(SCROLL_KEY) || "0";
    setTimeout(() => window.scrollTo(0, parseInt(scrollPosition)), 1);
  }
};

const setScrollPosition = () => {
  if (typeof sessionStorage !== "undefined") {
    sessionStorage.setItem(SCROLL_KEY, window.scrollY.toString());
  }
};

export const scrollToTop = (behavior: "smooth" | "auto" = "auto") => {
  if (typeof document !== "undefined") {
    const menuTop = document.getElementById("top");
    menuTop && menuTop.scrollIntoView({ behavior, block: "start" });
  }
};

export const trackScrollPosition = (shouldTrack = true) => {
  if (shouldTrack) {
    window.addEventListener("scroll", debounce(setScrollPosition, 200));
  } else {
    window.removeEventListener("scroll", debounce(setScrollPosition, 200));
  }
};
