import { useContext, useEffect, useRef } from "react";
import { sendClientSideSplitTrackEvent } from "@leafly-com/split-next";
import classnames from "classnames";
import { useSelector } from "react-redux";

import { DEFAULT_TAKE_COUNT } from "api/requests/getMenuItems";
import MenuContext from "context/MenuContext";
import { MenuItem } from "custom-types/MenuItem";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import useFilters from "hooks/useFilters";
import { getUserPrefersMedical } from "redux/selectors/user";
import { dispensaryHref } from "utils/dispensaryUrls";
import { scrollToTop } from "utils/scrollPosition";
import { getSplitKey } from "utils/split/getSplitKey";

import Carousel from "components/botanic/Carousel";
import MenuCard from "components/Dispensary/MenuCard";

const StaffPicksCarousel: React.FC<{
  cardsPerSlide?: number;
  className?: string;
  dispensaryId: number;
  dispensarySlug: string;
  filterable?: boolean;
  maxCardCount?: number;
  dispensaryRetailType: string;
}> = ({
  cardsPerSlide = 4,
  className,
  dispensaryId,
  dispensarySlug,
  filterable,
  maxCardCount = DEFAULT_TAKE_COUNT,
  dispensaryRetailType,
}) => {
  const countryCode = useDomainCountryCode();

  const { toggleFilter } = useFilters({ shallowRouting: true });

  const handleSeeMoreClick = (e: React.KeyboardEvent | React.MouseEvent) => {
    if (filterable) {
      e.preventDefault();
      toggleFilter({ [`whats_hot[]`]: "is_staff_pick" });
      setTimeout(scrollToTop, 100);
    }
  };

  const {
    selectors: { staffPicks },
    dispatch: { getStaffPicks },
  } = useContext(MenuContext);

  const userPrefersMedical = useSelector(getUserPrefersMedical);

  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      getStaffPicks();
    } else {
      isMounted.current = true;
    }
  }, [userPrefersMedical]);

  if (!staffPicks.length) {
    return null;
  }

  return (
    <div data-testid="staff-picks-carousel" className={classnames(className)}>
      <Carousel
        cardsPerSlide={cardsPerSlide}
        title={
          <h2
            className="heading--m font-extrabold"
            data-testid="section-header"
          >
            Staff favorites
          </h2>
        }
        seeMoreHref={dispensaryHref({
          additionalPath: `/menu?whats_hot[]=is_staff_pick`,
          countryCode,
          retailType: dispensaryRetailType,
          slug: dispensarySlug,
        })}
        seeMoreOnClick={handleSeeMoreClick}
      >
        {staffPicks.slice(0, maxCardCount).map((item: MenuItem) => (
          <MenuCard
            dispensaryId={dispensaryId}
            displayStaffPick={true}
            key={item.id}
            menuItem={item}
            onAddToBagClick={() => {
              sendClientSideSplitTrackEvent(
                "webDispensaryMenuItem_addToBagClick_frontend",
                getSplitKey(),
              );
            }}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default StaffPicksCarousel;
