import consumerApi from "api/services/consumer-api";
import { DISPENSARY_FILTER_NAMES } from "constants/dispensary";
import { PromotedBrand } from "custom-types/Brand";
import { MenuItem } from "custom-types/MenuItem";
import logError from "utils/logError";
import { normalizeLegacyParams } from "utils/normalizeLegacyParams";

import { formatParamsForGetMenuItems } from "./getMenuItems";

export type Placement = {
  brand: PromotedBrand;
  menuItems: MenuItem[];
};

/**
 * Retrieves a randomly selected brand offered by the dispensary to promote, along
 * with the menu items that should be featured.
 */
const getBrandPlacement = async (
  dispensarySlug: string,
  query: Record<string, string | string[] | number | boolean | undefined>,
): Promise<Placement | null> => {
  const { menuType, strategies, ...otherQueries } = query;
  const normalizedParams = normalizeLegacyParams(otherQueries);
  const { q, ...otherParams } = normalizedParams;

  try {
    const { data = null } = await consumerApi.get(
      `api/dispensaries/v1/${dispensarySlug}/promoted_brand_placement`,
      {
        params: {
          menuType: (menuType as string)?.toLowerCase(),
          strategies,
          ...(q && { q }),
          ...formatParamsForGetMenuItems(otherParams, DISPENSARY_FILTER_NAMES),
        },
      },
    );

    return data;
  } catch (e) {
    // A lot of dispensaries don't use this, and when that happens
    // a 404 is returned and is not useful to log.
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getBrandPlacement",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return null;
  }
};
export default getBrandPlacement;
