import React, { useContext } from "react";
import classnames from "classnames";
import pluralize from "pluralize";
import TimeAgo from "react-timeago";

import MenuContext from "context/MenuContext";

const LastUpdatedTime: React.FC<{
  className?: string;
  lastMenuUpdate: string;
  overrideTotalItems?: number;
}> = ({ className, lastMenuUpdate, overrideTotalItems }) => {
  const {
    selectors: { totalItems },
  } = useContext(MenuContext);

  if (!lastMenuUpdate) {
    return null;
  }

  const totalItemCount = overrideTotalItems || totalItems;
  const itemCount =
    !!totalItemCount && pluralize("product", totalItemCount, true);
  const label = itemCount ? `${itemCount} | ` : "";

  return (
    <div
      data-testid="last-updated-time"
      className={classnames("flex font-bold lg:justify-end text-xs", className)}
    >
      {`${label} Last updated:`}
      <TimeAgo date={lastMenuUpdate} className="ml-xs" />
    </div>
  );
};

export default LastUpdatedTime;
