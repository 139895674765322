import React, { useEffect } from "react";
import classnames from "classnames";
import Link from "next/link";

export type Props = {
  className?: string;
  heading?: string;
  linkHref?: string;
  linkOnClick?: () => void;
  linkText?: string;
  icon: React.ReactNode;
  message: string;
  showCaret?: boolean;
  trackImpression?: () => void;
};

const AlertBanner = ({
  className,
  heading,
  icon,
  linkHref,
  linkOnClick,
  linkText,
  message,
  showCaret,
  trackImpression,
}: Props) => {
  useEffect(() => {
    if (trackImpression) {
      trackImpression();
    }
  }, [trackImpression]);

  const showLink = linkText && linkHref;

  return (
    <div
      className={classnames(
        "relative flex items-center justify-between rounded border bg-yellow-20 border-yellow-40 px-lg py-md",
        className,
      )}
      data-testid="alert-banner"
    >
      <div className="flex items-center">
        {icon}

        <div className="flex flex-col text-xs ml-lg">
          <>
            <span className="font-extrabold">{heading}</span>
            <span>{message}</span>
          </>

          {showLink && (
            <Link
              onClick={linkOnClick}
              href={linkHref}
              className="lg:hidden underline text-xs font-bold"
            >
              {linkText}
            </Link>
          )}
        </div>
      </div>

      {showLink && (
        <Link
          onClick={linkOnClick}
          href={linkHref}
          className="hidden lg:block underline text-xs font-bold"
        >
          {linkText}
        </Link>
      )}

      {showCaret && (
        <>
          <div className="absolute right-[48px] w-0 h-0 border-solid border-t-[22px] border-x-[12px] border-transparent border-t-[#fffae6] top-[calc(100%-1px)] z-[3]"></div>
          <div className="absolute right-[48px] w-0 h-0 border-solid border-t-[22px] border-x-[12px] border-transparent border-t-[#fff0b3] top-[calc(100%+1px)] z-[2]"></div>
        </>
      )}
    </div>
  );
};

export default AlertBanner;
